import React from "react";

import "./Title.scss";

const Title = () => {
  return (
    <section className="title">
      <h1>
        Mariia <br className="br-none" />
        Koikonova
      </h1>
      <h3>Programadora Front-End</h3>
      <h4>Con sólida experiencia en diseño gráfico</h4>
    </section>
  );
};

export default Title;
